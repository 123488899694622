<template>
  <div>
  <div class="column full-width notification-wrapper help-center-sidebar">
    <div class="row justify-between q-py-sm q-px-md group-hover">
      <div class="row align-center">
        <div class="an-18 title">Resource Center</div>
      </div>
      <div class="close cursor-pointer" @click="toggleDrawerRight('help-center')">
        <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
      </div>   
    </div>
    <div class="overflow-auto" style="max-height: calc(100vh - 120px)">
      <div class="row justify-between q-py-sm q-px-md group-hover banner">
        <div class="row align-center banner-inner">
          <div class="flex justify-center flex-1 section">
            <img
              class="q-mb-sm cursor-pointer"
              src="/static/media-icons/click.svg"
              onclick="window.open('https://calendly.com/creative27/heycollab-30min'); return false;"
            />
            <p style="" >Demo</p>
          </div>
          <div class="flex justify-center flex-1 section">
            <img
              onclick="window.open('https://calendly.com/creative27/heycollab-30min'); return false;"
              class="q-mb-sm cursor-pointer"
              src="/static/media-icons/navigation.svg"
            />
            <p>Onboarding Help</p>
          </div>
          <div class="flex justify-center flex-1 section">
            <img
              class="q-mb-sm"
              src="/static/media-icons/light.svg"
            />
            <p>Request a Feature</p>
          </div>
        </div>
      </div>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="mediaExpansion"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/rocket-luncher.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">Getting started</p>
            <p class="description">
              Get up and sprinting in no time
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1015448048')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Intro to Heycollab</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1015512163')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Product Tour</p>
          </div>
          <div class="video" @click="videoPlayer('1015469012')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                style=""
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">3 steps to get you up and running</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="chat"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/chat.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">Chat</p>
            <p class="description">
              Pin, save & convert messages to tasks, share visual comments...
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1015746796')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Chat Overview</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1015760622')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Chat Tour</p>
          </div>
          <div class="video" @click="videoPlayer('1015858982')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                style=""
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Chat Tips and Tricks</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="tasks"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/tasks.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">Tasks</p>
            <p class="description">
              Create tasks, set custom labels, see tasks in calendar or list view
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1017176963')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Tasks Overview</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1017342701')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Tasks Tour</p>
          </div>
          <div class="video" @click="videoPlayer('1018020203')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                style=""
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Tasks Tips and Tricks</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="files"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/files.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">Files, Folders, Docs</p>
            <p class="description">
              Create folders, organize files, search, view and share and much more
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1018028909')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Files Overview</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1018047328')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Files Tour</p>
          </div>
          <div class="video" @click="videoPlayer('1018057376')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                style=""
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Docs</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1018061758')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                style=""
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Files Tips and Tricks</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="trackingTime"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/clock.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">Tracking time</p>
            <p class="description">
              Track your time, set hourly rate, view & export time-reports & more
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1018064335')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Time Tracking Overview</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1018068405')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">Time Tracking Tour</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="mytask"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/my-task.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">My tasks & My team</p>
            <p class="description">
              All tasks assigned to you in one neat place
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1018103344')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">My Tasks & My Team Overview</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1018111699')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">My Tasks & My Team Tour</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
      <q-expansion-item
        class="walkthrough walkthrough-small"
        header-class="q-px-none"
        v-model="savedItems"
        expand-icon-class="hidden"
      >
        <template v-slot:header>
          <div class="icon">
            <img
              class="q-mb-sm"
              src="/static/media-icons/save-item.svg"
            />
          </div>
          <div class="content">
            <p class="content-title">Saved items</p>
            <p class="description">
              Learn the task-boards section
            </p>
          </div>
        </template>
        <q-list class="q-pb-xs q-ml-xl">
          <div
            class="row"
          >
          <div class="video" @click="videoPlayer('1018108095')">
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">My Saved Items Overview</p>
          </div>
          <div class="video q-ml-md" @click="videoPlayer('1018109680')" >
            <div class="video-container">
              <q-icon
                class="play q-pa-md"
                :name="$icons.mdiFileVideoOutline"
                size="58px"
              />
            </div>
            <p class="q-mt-sm">My Saved Items Tour</p>
          </div>
          </div>
        </q-list>
      </q-expansion-item>
    </div>
  </div>
  <video-dialog
    v-if="showVideoModel"
    v-model="showVideoModel"
    :resetVideoDialog="resetVideoDialog"
    :videoMediaData="videoMediaData"
    :displayPlayer="displayPlayer"
  ></video-dialog>
</div>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import format from "date-fns/format";
import subDays from "date-fns/subDays";

import VideoDialog from "@/components/VuePlayer/VideoDialog";

export default {
  name: "HelpCenterSidebar",
  props: ["toggleDrawerRight", "reactOnPost"],
  components: {
    VideoDialog,    
  },
  api: {
    
  },
  data() {
    return {
      mediaExpansion: false,
      chat: false,
      tasks: false,
      files: false,
      trackingTime: false,
      mytask: false,
      savedItems: false,
      loading: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  methods: {
    videoPlayer(video_id) {
      let media = {
        isVimeo: true,
        file: `https://player.vimeo.com/video/${video_id}`,
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    todaytask() {
      return format(new Date(), "iiii, MMM. do");
    },
    yesterdayTask() {
      return format(subDays(new Date(), 1), "iiii, MMM. do");
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    allNotifications() {
      if (this.filteredNotifications) {
        if (this.filter.byUnreadAtTop) {
          let sortedArr = sortBy(this.filteredNotifications, (n) => n.read);
          // return sortedArr;
          return groupBy(sortedArr, (post) => {
            return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
          });
        }
        if (this.filter.byMessages) {
          let sortedArr = this.filteredNotifications.filter((n) => {
            if (n.action.object_type === "App\\Post") {
              return n;
            }
          });

          return groupBy(sortedArr, (post) => {
            return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
          });
        }
        if (this.filter.byTasks) {
          let sortedArr = this.filteredNotifications.filter((n) => {
            if (
              n.action.object_type === "App\\Task" ||
              n.action.object_type === "App\\TaskComment"
            ) {
              return n;
            }
          });
          return groupBy(sortedArr, (post) => {
            return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
          });
        }

        return groupBy(this.filteredNotifications, (post) => {
          return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
        });
      } else {
        return [];
      }
    },
    filteredNotifications() {
      return (
        this.notifications && this.notifications.filter((n) => n.action.object)
      );
    },
    allNotificationsCount() {
      let filteredNotifications = [];
      if (this.filteredNotifications) {
        filteredNotifications = this.filteredNotifications.filter(
          (n) => !n.read
        );
      }
      return filteredNotifications.length;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
  },
};
</script>
<style lang="scss" scoped>
.help-center-sidebar{
  font-family: 'Avenir Next';
  .title{
    font-weight: 600;
    color:#241D3B;
  }
  .banner{
    width: 100%;
    .banner-inner{
      align-items: baseline;
      border: 1px solid #F2F8FF;
      padding: 10px 0px 10px 0px;
      border-radius: 10px;
      background: #F2F8FF;width: 100%;
      .section{
        flex-direction: column;
        align-items: center;
        text-align: center;
        p{
          margin: 0px;
          font-size: 13px;
        }
      }
    }
  }
  .walkthrough-small {
    margin: 10px 16px 10px 16px;
    padding: 0px;
    .icon {
      margin: 5px 15px 5px 5px;
    }
    .content{
      max-width:100%;
      font-weight: 500;
      .content-title{
        font-size: 15px;
      }
      .description{
        font-size: 13px;
        white-space: pre-line;
        color: #68758B;
      }
    }
    .video{
      width: 90px;
      .video-container{
        border: 1px solid #FFEFF1;
        border-radius: 5px;
        cursor: pointer;
      }
      p{
        font-size: 13px;
        font-weight: 500;
      }
      .q-icon{
        color: #FF001F;
        background: #FFEFF1;
      }
    }
  }
}
</style>