<template>
  <q-toolbar
    flat
    dense
    class="rounded-borders"
    style="min-height:40px;height:43px;background:#FCFCFD;"
  >
    <div class="row flex-no-wrap items-center flex-1 justify-between" @click.stop="$emit('closeNotificaiton')">
      <div class="row flex-no-wrap items-center">
        <q-icon
          style="margin-left:10px"
          color="black"
          size="20px"
          class="q-mr-sm"
          :name="typeIcon"
        />
        <q-toolbar-title
          style="font-size:15px"
          shrink
          class="text-bold text-black q-px-none"
        >
          {{ currentWorkspace && currentWorkspace.title }}
        </q-toolbar-title>
        <q-btn
          :icon="$icons.mdiHelp"
          round
          size="md"
          class="help-button q-ml-sm"
          @click.stop="
            toggleAboutWalkthrough(!user.onboarding.is_folder_detail)
          "
        >
          <q-tooltip anchor="bottom start">
            Learn about the files section
          </q-tooltip>
        </q-btn>
      </div>
      <div v-if="isSearchVisible" class="row items-center toolbar-search">
        <q-input
          dense
          v-model="mediaSearch.searchText"
          @input="debounceSearchHandler"
          outlined
          placeholder="Search media or folder"
          :clear-icon="$icons.matClose"
          class="q-mr-xs"
        >
          <template v-slot:prepend>
            <q-icon size="xs" :name="$icons.matSearch" />
          </template>
          <template v-slot:append>
            <q-btn
              v-if="mediaSearch.searchText"
              @click="clearSearchHandler"
              flat
              dense
              round
              class="close-btn"
            >
              <q-icon
                :name="$icons.mdiCloseThick"
                class="absolute"
                style="font-size:12px"
                color="white"
              />
              <q-tooltip>Clear</q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
      <div class="row flex-no-wrap items-center">
        <q-btn
          no-caps
          :icon="$icons.matVideoCall"
          class="q-mr-sm flex-no-wrap video-call-btn"
          dense
          padding="0"
          v-if="canPostMessageFromWorkspace"
          @click="showTooltip = false"
          label="Start call"
        >
          <q-tooltip v-if="showTooltip">Start a call</q-tooltip>
          <q-menu
          auto-close
          :offset="[45, 5]"
          content-class="document-help-menu"
          @before-hide="showTooltip = true"
        >
          <q-list>
            <q-item
              @click="openVideoDailog(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id, isPeople)"
              clickable
              :class="['items-center']"
              ><q-icon
                :name="$icons.matVideoCall"
                size="20px"
                class="q-mr-md"
              />
              Join call
            </q-item>
            <q-item
              @click="copyVideoCallLink(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id)"
              clickable
              :class="['items-center']"
            >
              <q-icon size="20px" class="q-mr-md" :name="$icons.matLink" />
              Copy call link
            </q-item>
            <q-item
              @click="inviteVideoCallLink(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id)"
              clickable
              :class="['items-center']"
            >
              <q-icon size="20px" class="q-mr-md" :name="$icons.mdiContentCopy" />
              Copy meeting invitation
            </q-item>
          </q-list>
        </q-menu>
        </q-btn>
        <div>
          <q-btn
            v-if="mediaView === 'GridView'"
            size="md"
            flat
            round
            dense
            :icon="$icons.matDashboard"
            color="black"
            @click="changeView('ListView')"
            class="toolbar-buttons"
          >
            <q-tooltip>
              Grid View
            </q-tooltip>
          </q-btn>
          <q-btn
            v-else
            size="md"
            flat
            round
            dense
            color="black"
            :icon="$icons.matViewList"
            @click="changeView('GridView')"
            class="toolbar-buttons"
          >
            <q-tooltip>
              List View
            </q-tooltip>
          </q-btn>
        </div>
        <!-- <q-btn
          @click="fileUpload.dialog = true; showTooltip = false"
          :disabled="!canUploadMedia"
          size="md"
          flat
          round
          dense
          color="black"
          class="q-mr-xs toolbar-buttons"
          :icon="$icons.matAdd"
        >
          <q-tooltip v-if="showTooltip">
            Add files & create folders
          </q-tooltip>
          <q-menu auto-close @before-hide="showTooltip = true">
            <q-list style="width:185px" class="q-py-xs">
              <q-item @click="uploadFiles" clickable>
                <q-item-section>Upload files</q-item-section>
              </q-item>
              <q-item @click="checkMediaFolderAccess" clickable>
                <q-item-section>Create folder</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn> -->
        <q-separator
          class="self-center"
          style="height:17px;background:#E3E8ED"
          vertical
          inset
        />
        <q-btn
          flat
          round
          dense
          size="md"
          :color="isSearchVisible ? 'primary' : 'black'"
          @click.stop="isSearchVisible = !isSearchVisible"
          :icon="$icons.matSearch"
          class="toolbar-buttons q-ml-xs"
        >
          <q-tooltip> Search </q-tooltip>
        </q-btn>
        <q-btn
          flat
          round
          dense
          size="md"
          :style="{
            color: drawerRight === 'info' ? '#007AFF' : 'black',
          }"
          @click.native.stop="toggleDrawerRight('info')"
          :icon="$icons.matInfo"
          class="toolbar-buttons"
        >
          <q-tooltip>
            About
          </q-tooltip>
        </q-btn>
        <q-btn
          flat
          fab-mini
          dense
          size="md"
          round
          color="black"
          style="margin-top:2px"
          class="toolbar-buttons"
          :icon="$icons.mdiDotsHorizontal"
        >
          <q-tooltip>More</q-tooltip>
          <q-menu content-class="q-py-sm" auto-close>
            <q-item
              style="min-height: 24px;"
              @click.stop="preferredWorkspace"
              clickable
            >
              <q-item-section>
                {{ isPreferred ? "Remove from" : "Add to" }}
                favorite
              </q-item-section>
            </q-item>
            <q-item
              @click="pendingInvitationDialog = true"
              v-if="!isPeople"
              style="min-height: 24px;"
              clickable
            >
              <q-item-section>
                Pending invites
              </q-item-section>
            </q-item>
            <q-separator class="q-my-sm" style="background:#E3E8ED;" />
            <q-item
              style="min-height:24px"
              v-if="currentWorkspace.notification_level !== 'none'"
              @click="changeWorkspaceSetting('none')"
              clickable
            >
              <q-item-section>
                Mute workspace
              </q-item-section>
            </q-item>
            <q-item
              style="min-height:24px"
              v-else
              @click="changeWorkspaceSetting('all')"
              clickable
            >
              <q-item-section>
                Unmute workspace
              </q-item-section>
            </q-item>
            <q-item
              style="min-height:24px"
              @click="openNotificationSettingDialog"
              clickable
            >
              <q-item-section>
                Change notifications
              </q-item-section>
            </q-item>
            <q-separator class="q-my-sm" style="background:#E3E8ED;" />
            <q-item
              style="min-height: 24px;"
              @click="editView"
              v-if="
                !isPeople && (canChangeWorkspaceSetting || isWorkspaceOwner)
              "
              clickable
            >
              <q-item-section>Workspace settings</q-item-section>
            </q-item>
            <q-item
              @click="changeType"
              v-if="
                !isGeneral &&
                  !isPeople &&
                  (canChangeWorkspaceSetting || isWorkspaceOwner)
              "
              style="min-height: 24px;"
              clickable
            >
              <q-item-section>
                Change to {{ switchingType }} workspace
              </q-item-section>
            </q-item>
            <q-item
              @click="exportMessageCsv"
              style="min-height: 24px;"
              clickable
            >
              <q-item-section>Export conversation</q-item-section>
            </q-item>
            <q-item
              style="min-height: 24px;"
              class="text-red"
              @click="dialogHandler(true, 'leaveWorkspaceDialog')"
              v-if="!isWorkspaceOwner && !isGeneral && !isPeople"
              clickable
            >
              <q-item-section>
                Leave workspace
              </q-item-section>
            </q-item>
            <q-item
              style="min-height: 24px;"
              class="text-red"
              @click="dialogHandler(true, 'deleteWorkspaceDialog')"
              v-if="!isGeneral && isWorkspaceOwner && !isPeople"
              clickable
            >
              <q-item-section>Delete workspace</q-item-section>
            </q-item>
            <q-item
              style="min-height: 24px;"
              class="text-red"
              v-if="isPeople"
              @click="dialogHandler(true, 'deleteWorkspaceDialog')"
              clickable
            >
              <q-item-section>Delete conversation</q-item-section>
            </q-item>
          </q-menu>
        </q-btn>
      </div>
    </div>

    <file-uploader
      dropzoneid="media-view"
      v-model="fileUpload.dialog"
      :authToken="authToken"
      :workspace="currentWorkspaceId"
      :noquery="false"
      :user="user"
      :currentCompanyId="currentCompany.companyId"
      :imageList="imageList"
      :labels="taskLabels"
      :isWorkspaceOwner="isWorkspaceOwner"
      ref="mediaFileUploader"
      :isMediaViewUploader="true"
      @done="fileUploadCloseHandler"
      @cancel="fileUpload.dialog = false"
      @clickonmoreoption="clickonmoreoption"
      @deleteCommentPoint="deleteCommentPoint"
      @updatepositions="updatepositions"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
      @updateComment="updateComment"
      @sendComment="sendComment"
      @deleteMediaComment="deleteMediaComment"
      @closeMediaModel="closeMediaModel"
    ></file-uploader>
    <div v-if="deleteWorkspaceDialog.flag">
      <confirm-dialog
        v-model="deleteWorkspaceDialog.flag"
        title="Delete Workspace?"
        :question="
          `Are you sure you want to delete the ${currentWorkspace.title} Workspace ?`
        "
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'deleteWorkspaceDialog')"
        :successMethod="deleteWorkspace"
        :loading="deleteWorkspaceLoader"
      />
    </div>
    <div v-if="leaveWorkspaceDialog.flag">
      <confirm-dialog
        v-model="leaveWorkspaceDialog.flag"
        title="Leave Workspace ?"
        :question="
          `Are you sure you want to leave the ${currentWorkspace.title} Workspace ?`
        "
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'leaveWorkspaceDialog')"
        :successMethod="leaveWorkspace"
        :loading="leaveWorkspaceLoader"
      />
    </div>
    <create-media-folder-dialog
      v-if="createMediaFolderDialog.flag"
      v-model="createMediaFolderDialog.flag"
      :loading="createMediaFolderDialog.loading"
      :createFolderHandler="createFolderHandler"
      :cancelMethod="cancel"
    />
    <pending-invitations-dialog
      v-model="pendingInvitationDialog"
      v-if="pendingInvitationDialog"
      :workspace="currentWorkspace"
      :user="user"
    />
    <workspace-notification-settings
      :workspace="currentWorkspace"
      :notificationSettingDialog="notificationSettingDialog"
      :v-if="notificationSettingDialog"
      :closeDialog="closeNotificationSettingDialog"
    />
    <workspace-create-dialog
      v-if="updateWorkspace.show"
      v-model="updateWorkspace.show"
      @success="updateWorkspace.show = false"
      :workspace="currentWorkspace"
    />
  </q-toolbar>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";

import UserScope from "@/mixins/UserScope";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import BotMessenger from "@/mixins/BotMessenger";

import FileUploader from "@/components/Miscellaneous/FileUploader";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import WorkspaceCreateDialog from "@/components/Dialogs/WorkspaceCreateDialog";
import PendingInvitationsDialog from "@/components/Dialogs/PendingInvitationsDialog";
import WorkspaceNotificationSettings from "@/views/WorkspaceView/NavigationSidebar/WorkspaceNotificationSettings";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";
import { exportMessage } from "@/services/auth";

import {
  GetNewMediaListMutation,
  PreferredWorkspaceMutation,
  LeaveWorkspaceMutation,
  DeleteWorkspaceMutation,
  UpdateNotificationLevelMutation,
  UpdateWorkspaceMutation,
  CreateMediaFolderMutation,
  UpdateUserOnboardingMutation,
  NewMediaQuery,
} from "@/gql";
import mixpanel from "@/mixpanel";

export default {
  name: "MediaToolbar",
  mixins: [UserScope, MediaMethodMixin, BotMessenger],
  components: {
    FileUploader,
    ConfirmDialog,
    WorkspaceCreateDialog,
    PendingInvitationsDialog,
    WorkspaceNotificationSettings,
    CreateMediaFolderDialog,
  },
  props: [
    "toggleDrawerRight",
    "mediaSearch",
    "drawerRight",
    "changeView",
    "mediaView",
    "openVideoDailog",
    "copyVideoCallLink",
    "inviteVideoCallLink",
  ],
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
      defaultValue: [],
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      showTooltip:true,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      updateWorkspace: {
        show: false,
        type: "private",
      },
      deleteWorkspaceDialog: {
        flag: false,
      },
      leaveWorkspaceDialog: {
        flag: false,
      },
      pendingInvitationDialog: false,
      notificationSettingDialog: false,
      deleteWorkspaceLoader: false,
      leaveWorkspaceLoader: false,
      createMediaFolderDialog: {
        flag: false,
        loading: false,
      },
      isSearchVisible: false,
    };
  },
  methods: {
    debounceSearchHandler: debounce(async function (event) {
      console.log("event", event);
        const variables = {
          workspace_id: this.currentWorkspaceId,
          search:event,
        };

        const currentMedia = this.$api.getQueryByName("media");
        console.log("currentMedia", currentMedia);
        const newMedia = await this.$api.query({
          query: NewMediaQuery,
          variables,
        });

        if (newMedia.data.newMedia.length === 0) {
          this.removeloadButton = true;
        } else {
          currentMedia.data.newMedia = [
            ...newMedia.data.newMedia,
          ];
        }
    }, 300),
    checkMediaFolderAccess() {
      let eligible = this.$checkEligiblity("file_folders");
      if (eligible) {
        this.createMediaFolderDialog.flag = true;
      }
    },
    async toggleAboutWalkthrough(flag) {
      this.user.onboarding.is_folder_detail = flag;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_folder_detail",
          flag_value: flag,
        },
      });
      if (this.user.onboarding.is_folder_detail) {
        this.$mixpanelEvent("files-section-help", {});
      }
    },
    cancel() {
      this.createMediaFolderDialog.flag = false;
      this.createMediaFolderDialog.loading = false;
    },
    async createFolderHandler(title) {
      this.createMediaFolderDialog.loading = true;
      try {
        const variables = {
          title,
          workspace_id: this.currentWorkspaceId,
        };
        const response = await this.$api.mutate({
          mutation: CreateMediaFolderMutation,
          variables,
        });
        if (response) {
          const mediaFoldersQuery = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspaceId}`
          );
          const mediaFolder = {
            ...response.data.createMediaFolder,
            __typename: "mediaFolder",
            media_count: 0,
          };
          if (mediaFoldersQuery.data && mediaFoldersQuery.data.mediaFolders) {
            mediaFoldersQuery.data.mediaFolders.push(mediaFolder);
          } else {
            mediaFoldersQuery.data.mediaFolders = [mediaFolder];
          }
        }
        this.createMediaFolderDialog.loading = false;
        this.createMediaFolderDialog.flag = false;
        // this.$mixpanelEvent("create-folder", {});
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.createMediaFolderDialog.loading = false;
        this.createMediaFolderDialog.flag = false;
      }
    },
    uploadFiles() {
      this.$refs.mediaFileUploader.openFileDialog();
    },
    async changeType() {
      const type =
        this.currentWorkspace.type === "public" ? "private" : "public";
      const variables = {
        id: this.currentWorkspace.id,
        type,
      };
      const workspaceEntity = this.$api.getEntity(
        "workspace",
        this.currentWorkspace.id
      );
      workspaceEntity.type = type;
      const res = await this.$api.mutate({
        mutation: UpdateWorkspaceMutation,
        variables,
      });

      if (res) {
        await this.changeWorkspaceType({
          workspaceId: this.currentWorkspace.id,
          type,
        });
      }
    },
    dialogHandler(flag = true, dialog = "deleteWorkspaceDialog") {
      this[dialog] = {
        flag,
      };
    },
    async changeWorkspaceSetting(notificationLevel) {
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2.map((w) => {
          if (w.id === this.currentWorkspaceId) {
            w.notification_level = notificationLevel;
          }
        });
      }
      try {
        await this.$api.mutate({
          mutation: UpdateNotificationLevelMutation,
          variables: {
            workspace_id: this.currentWorkspaceId,
            notification_level: notificationLevel,
          },
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong..",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    openNotificationSettingDialog() {
      this.notificationSettingDialog = !this.notificationSettingDialog;
    },
    closeNotificationSettingDialog() {
      this.notificationSettingDialog = false;
    },
    async clearSearchHandler() {
      const variables = {
        workspace_id: this.currentWorkspaceId,
      };

      const currentMedia = this.$api.getQueryByName("media");
      const newMedia = await this.$api.query({
        query: NewMediaQuery,
        variables,
      });

      if (newMedia.data.newMedia.length === 0) {
        this.removeloadButton = true;
      } else {
        currentMedia.data.newMedia = newMedia.data.newMedia;
      }
      this.mediaSearch.searchText = "";
    },

    async fileUploadCloseHandler(fileList) {
      const mediaIds = fileList ? map(fileList, (f) => f.id) : [];
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });

      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspaceId}`);
      query.data.newMedia.push(...response.data.getNewMediaList);
      this.$q.loadingBar.stop();
    },
    async preferredWorkspace() {
      const variables = {
        workspace_id: this.currentWorkspaceId,
      };
      try {
        const workspace = this.$api.getEntity(
          "workspace",
          this.currentWorkspaceId
        );
        workspace.is_preferred = !workspace.is_preferred;
        await this.$api.mutate({
          mutation: PreferredWorkspaceMutation,
          variables,
        });
        mixpanel.track(
          `${
            workspace.is_preferred
              ? "Workspace Preferred Add"
              : "Workspace Preferred Remove"
          }`
        );
      } catch {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    async exportMessageCsv() {
      const res = await exportMessage(
        this.currentWorkspaceId,
        this.currentCompany.accessToken
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
    async leaveWorkspace() {
      this.leaveWorkspaceLoader = true;
      const workspaceId = this.currentWorkspace.id;
      const variables = {
        id: workspaceId,
      };
      await this.leavePublicWorkspaceAnnouncement({ workspaceId }); // mixin call
      await this.$api.mutate({
        mutation: LeaveWorkspaceMutation,
        variables,
      });
      this.leaveWorkspaceLoader = false;
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== workspaceId
        );
      }
      this.dialogHandler(false, "leaveWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    },
    editView() {
      this.updateWorkspace.show = !this.updateWorkspace.show;
    },
    async deleteWorkspace() {
      this.deleteWorkspaceLoader = true;
      const deletedWorkspaceId = this.currentWorkspaceId;

      const variables = {
        id: deletedWorkspaceId,
      };
      const query = this.$api.getQuery("Workspaces2Query");
      await this.$api.mutate({
        mutation: DeleteWorkspaceMutation,
        variables,
      });
      this.deleteWorkspaceLoader = false;
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== deletedWorkspaceId
        );
      }

      this.dialogHandler(false, "deleteWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : null;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isGeneral() {
      if (this.currentWorkspace) {
        return (
          this.currentWorkspace.type === "public" &&
          this.currentWorkspace.is_general
        );
      } else {
        return false;
      }
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    canUploadMedia() {
      return this.canPostMessageFromWorkspace || this.isWorkspaceOwner;
    },
    canRemove() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    type() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type;
      }
      return "public";
    },
    typeIcon() {
      let result;
      switch (this.type) {
        case "private":
          result = this.$icons.matLock;
          break;
        case "people":
          result = this.$icons.matPeople;
          break;
        default:
          result = this.$icons.fasBullhorn;
          break;
      }
      return result;
    },
    isPeople() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "people";
      } else {
        return false;
      }
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      } else {
        return [];
      }
    },
    preferredWorkspaces() {
      return this.workspaces && this.workspaces.filter((w) => w.is_preferred);
    },
    isPreferred() {
      if (this.preferredWorkspaces && this.currentWorkspace) {
        const preferredIds = this.preferredWorkspaces.map((element) => {
          return element.id;
        });
        return preferredIds.includes(this.currentWorkspace.id);
      } else {
        return false;
      }
    },
    switchingType() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "public" ? "private" : "public";
      }
      return "public";
    },
  },
};
</script>
